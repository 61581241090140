import { RoleRequirement, roles } from '@sekoia/shared/domain/User/roles';
import { Toggles } from './Toggles';

export class Paths {
  static readonly HOME = {
    href: '/',
  };
  static readonly NOTFOUND = {
    href: '/404',
  };
  static readonly APP = {
    href: '/app',
  };
  static readonly RESIDENTS = {
    href: `${Paths.APP.href}/residents`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.manager,
      roles.employee,
      roles.employeeUser,
      roles.medicineAdministrator,
      roles.relative,
    ]),
  };
  static readonly RESIDENT = {
    href: `${Paths.RESIDENTS.href}/:id`,
  };
  static readonly RESIDENT_ABOUT = {
    href: `${Paths.RESIDENT.href}/about`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly CONTACTS = {
    href: `${Paths.RESIDENT.href}/contacts/:optional?`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly CONTACTS_EDIT = {
    href: `${Paths.RESIDENT.href}/contacts/edit/:contactType?/:contactId?`,
  };
  static readonly INTERNAL_CONTACTS_EDIT = {
    href: `${Paths.RESIDENT.href}/internalcontacts/edit/:internalContactId?`,
  };
  static readonly FILES = {
    href: `${Paths.RESIDENT.href}/files`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator, roles.manager]),
    toggleName: Toggles.FILES,
  };
  static readonly CREATE_RESIDENT = {
    href: `${Paths.RESIDENTS.href}/createResident`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly BASIC_INFORMATION = {
    href: `${Paths.RESIDENT.href}/basicInformation`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly BASIC_INFORMATION_GENERAL = {
    href: `${Paths.BASIC_INFORMATION.href}/general`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly BASIC_INFORMATION_ADDITIONAL = {
    href: `${Paths.BASIC_INFORMATION.href}/additional`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly BASIC_INFORMATION_ANAMNESIS = {
    href: `${Paths.BASIC_INFORMATION.href}/anamnesis`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly BASIC_INFORMATION_CONSENT = {
    href: `${Paths.BASIC_INFORMATION.href}/consent`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly RESIDENT_RESTRICTING_ACCESSCONTROLTAGS = {
    href: `${Paths.BASIC_INFORMATION.href}/accesscontroltags`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_RESTRICTING_ACT,
  };
  static readonly RESIDENT_ACCESS = {
    href: `${Paths.BASIC_INFORMATION.href}/residentaccess`,
    toggleName: Toggles.RESIDENT_ACCESS,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly RESIDENT_IMAGES = {
    href: `${Paths.RESIDENT.href}/images`,
    roles: RoleRequirement.create([roles.employeeUser, roles.relative]),
    toggleName: Toggles.IMAGES2,
  };
  static readonly RESIDENT_IMAGES_ALBUM = {
    href: `${Paths.RESIDENT_IMAGES.href}/:albumId`,
    roles: RoleRequirement.create([roles.employeeUser, roles.relative]),
    toggleName: Toggles.IMAGES2,
  };
  static readonly IMAGES_LOCATIONS = {
    href: `${Paths.APP.href}/album/locations`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.IMAGES2,
  };
  static readonly IMAGES_LOCATIONS_LOCATION = {
    href: `${Paths.IMAGES_LOCATIONS.href}/:locationId`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.IMAGES2,
  };
  static readonly IMAGES_LOCATIONS_LOCATION_IMAGES = {
    href: `${Paths.IMAGES_LOCATIONS_LOCATION.href}/:albumId`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.IMAGES2,
  };
  static readonly ACTIVITIES = {
    href: `${Paths.APP.href}/activities`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.activityGroupMember,
    ]),
    toggleName: Toggles.ACTIVITIES,
  };
  static readonly ACTIVITY_GROUPS = {
    href: `${Paths.ACTIVITIES.href}/groups`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.activityGroupMember,
    ]),
    toggleName: Toggles.ACTIVITIES,
  };
  static readonly ACTIVITY_GROUP = {
    href: `${Paths.ACTIVITY_GROUPS.href}/:groupId/:tabKey`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.activityGroupMember,
    ]),
    toggleName: Toggles.ACTIVITIES,
  };
  static readonly ACTIVITIES_MEMBERS = {
    href: `${Paths.ACTIVITIES.href}/members`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.activityGroupMember,
    ]),
    toggleName: Toggles.ACTIVITIES,
  };
  static readonly ACTIVITIES_MEMBER_PROFILE = {
    href: `${Paths.ACTIVITIES_MEMBERS.href}/:memberId/profile`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.activityGroupMember,
    ]),
    toggleName: Toggles.ACTIVITIES,
  };
  static readonly ACTIVITIES_MEMBER_ACTIVITY_GROUPS = {
    href: `${Paths.ACTIVITIES_MEMBERS.href}/:memberId/groups`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.activityGroupMember,
    ]),
    toggleName: Toggles.ACTIVITIES,
  };
  static readonly CALENDAR = {
    roles: RoleRequirement.create([
      roles.relative,
      roles.employeeUser,
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
    ]),
    toggleName: Toggles.CALENDAR,
  };
  static readonly RESIDENT_CALENDAR = {
    href: `${Paths.RESIDENT.href}/calendar`,
    roles: RoleRequirement.create([
      roles.relative,
      roles.employeeUser,
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
    ]),
    toggleName: Toggles.CALENDAR,
  };
  static readonly RESIDENT_CHINA_BOOK = {
    href: `${Paths.RESIDENT.href}/chinabook`,
    toggleName: Toggles.CHINA_BOOK,
  };
  static readonly RESIDENT_CHINA_BOOK_CONVERSATION = {
    href: `${Paths.RESIDENT.href}/chinabook/:conversationId`,
    toggleName: Toggles.CHINA_BOOK,
  };
  static readonly OBSERVATIONS = {
    href: `${Paths.APP.href}/observations`,
    roles: RoleRequirement.create([roles.employeeUser, roles.citizenUser]),
  };
  static readonly RESIDENT_FORMS = {
    href: `${Paths.RESIDENT.href}/forms/:optional?`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly RESIDENT_FORM_DETAIL = {
    href: `${Paths.RESIDENT.href}/forms/detail/:formId/:tabName(form|notifications)?`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator]),
  };
  static readonly FORMS_STANDARD = {
    href: `${Paths.APP.href}/standardForms`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator]),
  };
  static readonly FORM_DETAIL = {
    href: `${Paths.APP.href}/standardForms/:formId/:tabName(form|settings|setup|security|notifications)?`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator]),
  };
  static readonly OBSERVATIONS_REPORT = {
    href: `${Paths.OBSERVATIONS.href}/report`,
    roles: Paths.OBSERVATIONS.roles,
  };
  static readonly RESIDENT_INSTRUCTIONS = {
    href: `${Paths.RESIDENT.href}/instructions`,
    toggleName: Toggles.INSTRUCTIONS,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly RESIDENT_INSTRUCTIONS_INSTRUCTION = {
    href: `${Paths.RESIDENT_INSTRUCTIONS.href}/:instructionId`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
    toggleName: Toggles.INSTRUCTIONS,
  };

  static readonly RESIDENT_PLANS = {
    href: `${Paths.RESIDENT.href}/plans/:optional?`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };

  static readonly RESIDENT_PLAN = {
    href: `${Paths.RESIDENT_PLANS.href}/:planId?`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };

  static readonly RESIDENT_PLAN_VUM = {
    href: `${Paths.RESIDENT.href}/plans/vum/:problemId?/:subGoalId?`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
    toggleName: Toggles.PLANS_VUM,
  };

  static readonly RESIDENT_PLAN_VUM_REPORT_FORMS = {
    href: `${Paths.RESIDENT.href}/plans/vum/:problemId?/:subGoalId?/formsReport`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
    toggleName: Toggles.PLANS_VUM,
  };

  static readonly RESIDENT_PLAN_VUM_REPORT_TASKS = {
    href: `${Paths.RESIDENT.href}/plans/vum/:problemId?/:subGoalId?/taskReport`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
    toggleName: Toggles.PLANS_VUM,
  };

  static readonly RESIDENT_PLAN_REPORT_FORMS = {
    href: `${Paths.RESIDENT_PLANS.href}/:planId?/formsReport`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };

  static readonly RESIDENT_PLAN_REPORT_TASKS = {
    href: `${Paths.RESIDENT_PLANS.href}/:planId?/taskReport`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };

  static readonly TASKS = {
    href: `${Paths.APP.href}/tasks`,
    roles: RoleRequirement.create([
      roles.manager,
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
  };
  static readonly TASKS_STANDARD_TASKS = {
    href: `${Paths.TASKS.href}/standardtasks`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator, roles.medicineAdministrator]),
  };

  static readonly TASKS_STANDARD_TASKS_DETAIL = {
    href: `${Paths.TASKS_STANDARD_TASKS.href}/:organizationId/:standardTaskId/:tabName(content|settings|delegations|notifications|security)?`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator, roles.medicineAdministrator]),
  };

  static readonly RESIDENT_TASKS = {
    href: `${Paths.RESIDENT.href}/tasks`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
  };

  static readonly RESIDENT_TASK = {
    href: `${Paths.RESIDENT_TASKS.href}/:taskId?/:taskDate?`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
  };

  static readonly RESIDENT_NEXUS_DISPENCED = {
    href: `${Paths.RESIDENT.href}/tasks/nexus/dispenced`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
  };

  static readonly RESIDENT_NEXUS_NOT_DISPENCED = {
    href: `${Paths.RESIDENT.href}/tasks/nexus/notdispenced/:prescriptionId?`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
  };

  static readonly RESIDENT_TASK_SETTINGS = {
    href: `${Paths.RESIDENT_TASKS.href}/:taskId?/:taskDate?/settings`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
  };

  static readonly RESIDENT_TASK_DELEGATIONS = {
    href: `${Paths.RESIDENT_TASKS.href}/:taskId?/:taskDate?/delegations`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
  };

  static readonly RESIDENT_TASK_LOG = {
    href: `${Paths.RESIDENT_TASKS.href}/:taskId?/:taskDate?/log`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
  };

  static readonly TASKS_ASSIGN_MULTIPLE_RESIDENTS = {
    href: `${Paths.TASKS.href}/assign`,
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
  };

  static readonly TASKS_NURSE_TASKS = {
    href: `${Paths.TASKS.href}/nursetasks`,
    roles: Paths.TASKS.roles,
    toggleName: Toggles.NURSE_TASKS_FORECASTING,
  };
  static readonly TASKS_NURSE_TASKS_REPORT = {
    href: `${Paths.TASKS.href}/nursetasksreport`,
    roles: Paths.TASKS.roles,
    toggleName: Toggles.TASKS_NURSE_REPORT,
  };
  static readonly TASKS_REPORT_DEFAULT = {
    href: `${Paths.TASKS.href}/report`,
    roles: Paths.TASKS.roles,
  };
  static readonly TASKS_REPORT = {
    href: `${Paths.TASKS_REPORT_DEFAULT.href}/:from?/:to?`,
    roles: Paths.TASKS.roles,
  };
  static readonly TASKS_REPORT_DETAILS = {
    href: `${Paths.TASKS_REPORT.href}/resident/:residentId/:taskState?`,
    roles: Paths.TASKS.roles,
  };
  static readonly FLUIDS = {
    roles: RoleRequirement.create([
      roles.superUser,
      roles.administrator,
      roles.systemAdministrator,
      roles.medicineAdministrator,
    ]),
    toggleName: Toggles.FLUIDS,
  };
  static readonly RESIDENT_FLUIDS_TARGET = {
    href: `${Paths.RESIDENT.href}/fluids`,
    roles: Paths.FLUIDS.roles,
    toggleName: Toggles.FLUIDS,
  };
  static readonly RESIDENT_INVENTORY = {
    href: `${Paths.RESIDENT.href}/inventory`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly RESIDENT_INVENTORY_ITEM = {
    href: `${Paths.RESIDENT_INVENTORY.href}/:inventoryId`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly RESIDENT_INVENTORY_ITEM_CONTENT = {
    href: `${Paths.RESIDENT_INVENTORY_ITEM.href}/content`,
    roles: RoleRequirement.create([roles.superUser, roles.systemAdministrator, roles.administrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly RESIDENT_INVENTORY_ITEM_SERVICE = {
    href: `${Paths.RESIDENT_INVENTORY_ITEM.href}/service`,
    roles: RoleRequirement.create([roles.superUser, roles.systemAdministrator, roles.administrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly RESIDENT_INVENTORY_ITEM_LOAN = {
    href: `${Paths.RESIDENT_INVENTORY_ITEM.href}/loan`,
    roles: RoleRequirement.create([roles.superUser, roles.systemAdministrator, roles.administrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly RESIDENT_INVENTORY_ADD = {
    href: `${Paths.RESIDENT_INVENTORY.href}/add`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly FLUIDS_REPORT = {
    href: `${Paths.APP.href}/fluids/report`,
    roles: Paths.FLUIDS.roles,
    toggleName: Toggles.FLUIDS,
  };
  static readonly FLUIDS_CONFIGURATION = {
    href: `${Paths.APP.href}/fluids/configuration`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.FLUIDS,
  };
  static readonly RESIDENT_HEALTH_MEASUREMENTS = {
    href: `${Paths.RESIDENT.href}/healthmeasurements`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.HEALTH_MEASUREMENTS,
  };
  static readonly RESIDENT_RECORDS = {
    href: `${Paths.RESIDENT.href}/records`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.RECORDS,
  };
  static readonly RECORDS = {
    href: `${Paths.APP.href}/records`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly RESIDENT_MEDICINE = {
    href: `${Paths.RESIDENT.href}/medicine/:optional?`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_MEDICINE,
    professionLogoTypeIds: [6],
  };
  static readonly RESIDENT_MEDICINE_NOT_DISPENSED_PRESCRIPTION = {
    href: `${Paths.RESIDENT.href}/medicine/prescription/notDispensed/:prescriptionId`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_MEDICINE,
    professionLogoTypeIds: [6],
  };
  static readonly RESIDENT_MEDICINE_PN_PRESCRIPTION = {
    href: `${Paths.RESIDENT.href}/medicine/prescription/pn/:prescriptionId`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_MEDICINE,
    professionLogoTypeIds: [6],
  };
  static readonly RESIDENT_MEDICINE_PN_PRESCRIPTION_SETTINGS = {
    href: `${Paths.RESIDENT_MEDICINE_PN_PRESCRIPTION.href}/settings`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_MEDICINE,
    professionLogoTypeIds: [6],
  };
  static readonly RESIDENT_MEDICINE_PN_PRESCRIPTION_DELEGATIONS = {
    href: `${Paths.RESIDENT_MEDICINE_PN_PRESCRIPTION.href}/delegations`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_MEDICINE,
    professionLogoTypeIds: [6],
  };
  static readonly RESIDENT_MEDICINE_PN_PRESCRIPTION_NOTIFICATIONS = {
    href: `${Paths.RESIDENT_MEDICINE_PN_PRESCRIPTION.href}/notifications`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_MEDICINE,
    professionLogoTypeIds: [6],
  };
  static readonly RESIDENT_MEDICINE_INVENTORY = {
    href: `${Paths.RESIDENT.href}/medicine/inventory/:inventoryId?`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_MEDICINE,
    professionLogoTypeIds: [6],
  };
  static readonly RESIDENT_MEDICINE_INVENTORY_NOTIFICATIONS = {
    href: `${Paths.RESIDENT_MEDICINE_INVENTORY.href}/notifications`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_MEDICINE,
    professionLogoTypeIds: [6],
  };
  static readonly MANAGEMENT = {
    href: `${Paths.APP.href}/management`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_CATERGORY_CONFIGURATION = {
    href: `${Paths.MANAGEMENT.href}/categoryConfiguration`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_CATEGORIES = {
    href: `${Paths.MANAGEMENT.href}/categories`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_CATEGORIES_EDIT = {
    href: `${Paths.MANAGEMENT_CATEGORIES.href}/:categoryId/:tabName(categoryArea|configuration|security)?`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_CATEGORIES_EDIT_SUBCATEGORY = {
    href: `${Paths.MANAGEMENT_CATEGORIES_EDIT.href}/subCategory/:subCategoryId`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_HEALTHMEASUREMENTS = {
    href: `${Paths.MANAGEMENT.href}/healthmeasurements`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.HEALTH_MEASUREMENTS,
  };
  static readonly MANAGEMENT_MEDICINE = {
    href: `${Paths.MANAGEMENT.href}/medicine`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.RESIDENT_MEDICINE,
  };
  static readonly MANAGEMENT_RESIDENT_DATA = {
    href: `${Paths.MANAGEMENT.href}/residentData`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.ANAMNESIS_NURSING_STATUS,
  };
  static readonly MANAGEMENT_INCIDENTS = {
    href: `${Paths.MANAGEMENT.href}/incidents`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.INCIDENTS,
  };
  static readonly MANAGEMENT_INVENTORY = {
    href: `${Paths.MANAGEMENT.href}/inventory`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly MANAGEMENT_INVENTORY_SERVICE_TYPE = {
    href: `${Paths.MANAGEMENT_INVENTORY.href}/servicetype`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly MANAGEMENT_INVENTORY_SOURCE_FROM = {
    href: `${Paths.MANAGEMENT_INVENTORY.href}/sourcefrom`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly MANAGEMENT_PROFESSIONS_DELEGATIONS = {
    href: `${Paths.MANAGEMENT.href}/professions/delegations`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_PROFESSIONS_ACT = {
    href: `${Paths.MANAGEMENT.href}/professions/act`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_EMPLOYEES = {
    href: `${Paths.MANAGEMENT.href}/employees`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_LOCATIONS = {
    href: `${Paths.MANAGEMENT.href}/locations`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_ACCESS_CONTROL = {
    href: `${Paths.MANAGEMENT.href}/accesscontrol`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_EMPLOYEES_CREATE_EMPLOYEE = {
    href: `${Paths.MANAGEMENT_EMPLOYEES.href}/create`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator]),
  };
  static readonly MANAGEMENT_EMPLOYEES_EMPLOYEE = {
    href: `${Paths.MANAGEMENT_EMPLOYEES.href}/:employeeProfileId/:subPage(about|security|delegation|accesscontrol)?`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator]),
  };
  static readonly PROFILE = {
    href: `${Paths.APP.href}/profile/:userLegacyId/:subPage(about|security|delegations)?`,
  };
  static readonly BACKEND = {
    href: `${Paths.APP.href}/backend`,
    roles: RoleRequirement.create([roles.sekoiaAdmin]),
  };
  static readonly BACKEND_EMPLOYEE_GROUPS = {
    href: `${Paths.BACKEND.href}/employeegroups`,
    roles: RoleRequirement.create([roles.sekoiaAdmin]),
  };
  static readonly INVITATIONS = {
    href: '/invitations',
  };
  static readonly INVITATIONS_RELATIVE = {
    href: `${Paths.INVITATIONS.href}/relative/:key`,
  };
  static readonly INVITATIONS_RELATIVE_LOGIN = {
    href: `${Paths.INVITATIONS.href}/login/:key`,
  };
  static readonly INVITATIONS_GROUP = {
    href: `${Paths.INVITATIONS.href}/group/:key`,
  };
  static readonly INVITATIONS_GROUP_LOGIN = {
    href: `${Paths.INVITATIONS.href}/group/login/:key`,
  };
  static readonly NOTIFICATIONS = {
    href: `${Paths.APP.href}/notifications`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly NOTIFICATIONS_DETAIL = {
    href: `${Paths.NOTIFICATIONS.href}/:id`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly MY_REPORTS = {
    href: `${Paths.APP.href}/myreports`,
    roles: RoleRequirement.create([roles.employeeUser]),
  };
  static readonly RESET = {
    href: '/reset',
  };
  static readonly RESET_PASSWORD = {
    href: `${Paths.RESET.href}/password/:key`,
  };
  static readonly DASHBOARD_CONFIGURATION = {
    href: `${Paths.APP.href}/dashboard/configuration`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly DASHBOARD_CONFIGURATION_EDIT = {
    href: `${Paths.DASHBOARD_CONFIGURATION.href}/:dashboardId`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly DASHBOARD_CONFIGURATION_EDIT_SHARING = {
    href: `${Paths.DASHBOARD_CONFIGURATION.href}/:dashboardId/sharing`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly DASHBOARD_WIDGET_EDIT = {
    href: `${Paths.DASHBOARD_CONFIGURATION.href}/:dashboardId/:widgetId`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly DASHBOARD_MY = {
    href: `${Paths.APP.href}/dashboard/my`,
    roles: RoleRequirement.create([
      roles.employeeUser,
      roles.superUser,
      roles.manager,
      roles.administrator,
      roles.systemAdministrator,
    ]),
  };
  static readonly DASHBOARD_MY_MAIN = {
    href: `${Paths.DASHBOARD_MY.href}/main/:dashboardId`,
    roles: RoleRequirement.create([
      roles.employeeUser,
      roles.superUser,
      roles.manager,
      roles.administrator,
      roles.systemAdministrator,
    ]),
  };
  static readonly DASHBOARD_MY_PLANS = {
    href: `${Paths.DASHBOARD_MY.href}/plans`,
    roles: RoleRequirement.create([roles.superUser, roles.administrator, roles.systemAdministrator]),
  };
  static readonly DASHBOARD_MY_MANAGEMENT_INFORMATION = {
    href: `${Paths.DASHBOARD_MY.href}/managementInformation`,
    roles: RoleRequirement.create([roles.manager, roles.administrator, roles.systemAdministrator]),
  };
  static readonly DASHBOARD_MY_DRILLDOWN = {
    href: `${Paths.DASHBOARD_MY.href}/drilldown/:dashboardId/:widgetId/:locationIds`,
    roles: RoleRequirement.create([
      roles.employeeUser,
      roles.superUser,
      roles.manager,
      roles.administrator,
      roles.systemAdministrator,
    ]),
  };
  static readonly INCIDENTS = {
    href: `${Paths.APP.href}/incidents`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.INCIDENTS,
  };
  static readonly INCIDENT_CONTENT = {
    href: `${Paths.INCIDENTS.href}/incident/:id/content`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.INCIDENTS,
  };
  static readonly INCIDENT_ANALYSIS = {
    href: `${Paths.INCIDENTS.href}/incident/:id/analysis`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator]),
    toggleName: Toggles.INCIDENTS,
  };
  static readonly INCIDENT_ACTION = {
    href: `${Paths.INCIDENTS.href}/incident/:id/action`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator]),
    toggleName: Toggles.INCIDENTS,
  };
  static readonly INCIDENT_FINAL_COMMENT = {
    href: `${Paths.INCIDENTS.href}/incident/:id/finalComment`,
    roles: RoleRequirement.create([roles.administrator, roles.systemAdministrator]),
    toggleName: Toggles.INCIDENTS,
  };
  static readonly INCIDENT_NEW = {
    href: `${Paths.INCIDENTS.href}/incident_new/:id`,
    roles: RoleRequirement.create([roles.employeeUser]),
    toggleName: Toggles.INCIDENTS,
  };
  static readonly INVENTORY = {
    href: `${Paths.APP.href}/inventory`,
    roles: RoleRequirement.create([roles.systemAdministrator, roles.administrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly INVENTORY_CONFIGURATION = {
    href: `${Paths.INVENTORY.href}/configuration`,
    roles: RoleRequirement.create([roles.systemAdministrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly INVENTORY_LIST = {
    href: `${Paths.INVENTORY.href}/list`,
    roles: RoleRequirement.create([roles.systemAdministrator, roles.administrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly INVENTORY_ITEM = {
    href: `${Paths.INVENTORY.href}/:id`,
    roles: RoleRequirement.create([roles.systemAdministrator, roles.administrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly INVENTORY_ITEM_CONTENT = {
    href: `${Paths.INVENTORY_ITEM.href}/content`,
    roles: RoleRequirement.create([roles.systemAdministrator, roles.administrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly INVENTORY_ITEM_SERVICE = {
    href: `${Paths.INVENTORY_ITEM.href}/service`,
    roles: RoleRequirement.create([roles.systemAdministrator, roles.administrator]),
    toggleName: Toggles.INVENTORY,
  };
  static readonly INVENTORY_ITEM_LOAN = {
    href: `${Paths.INVENTORY_ITEM.href}/loan`,
    roles: RoleRequirement.create([roles.systemAdministrator, roles.administrator]),
    toggleName: Toggles.INVENTORY,
  };
}
